import styled from 'styled-components'

import { theme } from '@/styles/theme'

type TopPageTitleProps = {
  titleText: string
  subtitleText: string
  imgUrl?: string
}

export const TopPageTitle = ({
  titleText,
  subtitleText,
  imgUrl,
}: TopPageTitleProps) => {
  return (
    <TopDivColor>
      <Image src={imgUrl} alt="" />
      <TopTitleDiv>
        <PageTitle>{titleText}</PageTitle>
        <PageSubTitle>{subtitleText}</PageSubTitle>
      </TopTitleDiv>
    </TopDivColor>
  )
}

const TopDivColor = styled.div`
  height: 337px;
  position: relative;
  background: rgba(0, 45, 101, 0.6);

  @media screen and (max-width: 1047px) {
    height: 180px;
  }
`

const Image = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const TopTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);

  color: #ffffff;
  text-align: center;
`

const PageTitle = styled.h1`
  font-size: 42px;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 8px;
  font-family: ${theme.fontEn};

  @media screen and (max-width: 1047px) {
    display: none;
  }
`
const PageSubTitle = styled.h2`
  font-size: 32px;
  line-height: 46px;
  font-weight: normal;

  @media screen and (max-width: 1047px) {
    font-size: 24px;
    line-height: 35px;
    font-weight: bold;
  }
`
